// import { style } from '@mui/system';
import React, { useState } from 'react';
import { Modal} from 'react-bootstrap';
import { Link } from 'gatsby';
import AgentMLSAccount from '../../pages/agents/AgentChat/agentaccount';
import AgentVerifypopup from './AgentVerifypopup';

export default function JoinNowPopup(props) {
  const { shows, toggleShows } = props;
  const notShow=true
  const [isOpen, setIsOpen] = useState("")

  // const [showed, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  const closePopupList=()=>{
    toggleShows()
    setIsOpen("")
  }
  
  const preview =()=>{
    setIsOpen("")
  }
  const closeMSLAgent=()=>{
    setIsOpen("email")
  }
  return (
    <div>
      {notShow ? (
        <Modal show={shows} onHide={closePopupList} backdrop="static" className='ModalDiv'>
          <div style={{border:"1px solid rgb(4, 144, 251)", borderRadius:"13px"}}>
          <Modal.Header closeButton className="team-popup-margin teampopup-title" >
           
          </Modal.Header>
          <Modal.Body className="team-popup-margin pb-0" >
           
            {isOpen === "" &&
              <>
                <div className='register-iconsection'>
                  <Link to="/agents" ><img className='newagent-logo' src="/images/logo.png" alt="logo"/></Link>
                  <p className='registration font-weight-bold'>Agent Registration</p>
                </div>
                <AgentMLSAccount closeAgent={closeMSLAgent}/>
              </>
            }
            {isOpen === "email" &&
              <AgentVerifypopup closeList={closePopupList} preview={preview}/>
            }
          </Modal.Body>
          </div>
        </Modal>
      ) : null}
      {/* <Modal show={showed} onHide={handleClose}>

        <div className='register-iconsection'>
          <Link to="/agents" ><img className='newagent-logo' src="/images/logo.png" /></Link>
          <p className='registration font-weight-bold'>Agent Registration</p>
        </div>

        <Modal.Body >
          <AgentMLSAccount />
        </Modal.Body>

      </Modal> */}
    </div>
  )
}
